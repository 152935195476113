import Vue from 'vue'
import axios from 'axios'

axios.defaults.baseURL = 'https://plataforma.menuvem.com.br/application/group-1471ed47b6159f4f7ab53bddbcb75f67/client-1bdc0d7ac4181666eb50883d0a0cf209/'
//https://s3-sa-east-1.amazonaws.com/nuc-storage.menuvem.com.br/application/group-1471ed47b6159f4f7ab53bddbcb75f67/img/logo-a.png

Vue.use({
    install(Vue){
        Vue.prototype.$http = axios
    }
})